<template>
	<div
		:class="['post', { 'post-background': background, 'post-detail-popup': showDetailPage }]"
		:id="id"
		:data-publication-status="publication_status"
		:data-audience="audience"
	>
		<div
			v-if="attachments && showDetailPage"
			:class="['post-media hide-mobile', { 'post-media-published ': isPublishedPost && !detailPage }]"
			style="position: relative"
			@contextmenu="(e) => e.preventDefault()"
			ref="mediaContent"
		>
			<component
				:is="`post-media-${type}`"
				v-bind="attachments"
				:id="id"
				:is-owner="isOwnPost"
				@videoLoaded="onVideoLoaded"
				@load="$emit('load')"
			/>
			<div v-if="isBluredLoader" class="blur flex center align">
				<div class="i-spinner rotating post-subscribe-spinner" />
			</div>
			<div
				v-if="isLocked"
				:class="['lock big', { 'lock-blurred-big': !hasBlurredImage }]"
				@click="openSubscriptionPage"
			/>
		</div>
		<button v-if="showDetailPage" type="button" @click="closeDetailPage" class="dialog-close-btn hide-mobile">
			<slot name="header-icon">
				<i class="i-cross" />
			</slot>
		</button>
		<div :class="['post-about', { 'post-about-with-comment': isConsumer }]">
			<div class="post-header">
				<router-link :to="girlProfileLink" class="post-author-info" :data-girl-id="author.id">
					<avatar-circle :image-path="author.avatar" />
					<div class="post-data flex column center">
						<div class="post-name" @click="onClickNickname">
							{{ author.nickname }}
							<span v-if="author?.has_in_fans_current_consumer" class="name-badge" />
						</div>
						<div class="post-labels">
							<div v-if="isPublishedPost" class="post-label post-release-date">{{ published_text }}</div>
							<div v-else-if="isScheduledPost && isOwnPost" class="post-label post-planned-date">
								<svg-icon name="clock-plan" :size="postLabelIconSize" />
								{{ shouldBePublishedAt }}
							</div>
							<div class="post-label post-audience" v-if="postForFans">
								<svg-icon name="star" :size="postLabelIconSize" />
								Для фанатов
							</div>
						</div>
					</div>
				</router-link>
				<subscribe-button
					:id="author.id"
					:avatar="author.avatar"
					v-if="(isConsumer && !detailPage) || isGuest"
					:btn-class="detailPage ? 'post-subscribe post-subscribe-dialog' : 'post-subscribe'"
					:is-subscribed="author.has_in_fans_current_consumer"
					:is-waiting-subscription-result="isWaitingSubscriptionResult"
					:is-subscribe-renewable="isSubscribeRenewable"
					:subscribed-till="author.girl_subscription_expires_at"
				/>
				<button type="button" class="post-options-button" v-if="isOwnPost" @click="showPostOptionsModal">
					<svg-icon name="ellipsis-vertical" />
				</button>
				<button v-if="showDetailPage" type="button" @click="closeDetailPage" class="dialog-close-btn hide-desktop">
					<slot name="header-icon">
						<i class="i-cross" />
					</slot>
				</button>
				<!--					<div>-->
				<!--						<svg-icon name="rub" size="20px" />-->
				<!--						Чаевые-->
				<!--					</div>-->
			</div>
			<text-with-hashtags-and-mentioned
				class="post-description"
				:text="description"
				:hashtags="hashtags"
				:mentioned="mentioned"
				:preview-letters-count="detailPage ? description.length : previewLettersCount"
			/>
			<div
				v-if="attachments"
				:class="[
					'post-media',
					{ 'post-media-published': isPublishedPost && !detailPage, 'hide-desktop': showDetailPage }
				]"
				style="position: relative"
				@click="checkOpenDetailPage(id, publication_status)"
				@contextmenu="(e) => e.preventDefault()"
			>
				<component
					:is="`post-media-${type}`"
					v-bind="attachments"
					:id="id"
					:is-owner="isOwnPost"
					@videoLoaded="onVideoLoaded"
				/>
				<div v-if="isBluredLoader" class="blur flex center align">
					<div class="i-spinner rotating post-subscribe-spinner" />
				</div>
				<div
					v-if="isLocked"
					:class="['lock big', { 'lock-blurred-big': !hasBlurredImage }]"
					@click="openSubscriptionPage"
				/>
			</div>
			<post-status-for-girl
				class="post-status"
				v-if="isGirl && !isPublishedPost"
				:audience="audience"
				:publication_status="publication_status"
				:verification-status="verificationStatus"
				@show-post-rejected-dialog="showPostRejectedDialog = true"
				@show-verification-needs-dialog="showVerificationNeedsDialog = true"
				@show-verification-process-dialog="showVerificationProcessDialog = true"
				@show-verification-rejected-dialog="showVerificationRejectedDialog = true"
			/>

			<div v-if="isPublishedPost" class="post-like-panel">
				<div class="flex row space">
					<div class="post-actions">
						<post-like
							v-if="isConsumer || isGuest"
							@clickLike="showPopUpAboutLike"
							:amount="total_consumer_likes"
							:post-id="id"
						/>
						<div
							:class="[
								'post-comment-link',
								{ 'post-comment-link-pink': !girl_liked_any_comment && total_comments > 0 && isOwnPost }
							]"
							@click="checkOpenDetailPage(id, publication_status, true)"
						>
							<svg-icon name="message" size="19px" />
							<span v-if="total_comments" class="post-like-text">{{ total_comments }}</span>
						</div>
						<div
							class="liked-comments-label hide-mobile"
							v-if="!girl_liked_any_comment && total_comments > 0 && isOwnPost"
							@click="checkOpenDetailPage(id, publication_status, true)"
						>
							Выбери лучший комментарий
						</div>
						<div v-if="showPrivateBtn" class="private-link" @click="openChat">
							<svg-icon name="chat-outline" size="20px" />
							Приват
						</div>
						<!--					<div>-->
						<!--						<svg-icon name="rub" size="20px" />-->
						<!--						Чаевые-->
						<!--					</div>-->
					</div>
				</div>
				<router-link :to="{ name: 'post-likes', params: { id } }" class="post-like-counter">
					<span :total-like-count="total_likes" class="post-total-likes">Нравится: {{ total_likes }}</span>
				</router-link>
			</div>
			<slot v-if="isPublishedPost" name="comments">
				<div v-if="comment" class="post-comments">
					<comment-component
						:preview-letters-count="70"
						:nickname="comment.consumer_nickname"
						:text="comment.text"
						:avatar="comment.consumer_avatar"
						:author-id="comment.consumer_id"
						:consumer-has-girl-subscription="comment?.consumer_has_girl_subscription"
						:liked-avatar="author.avatar"
						:liked-at-text="girl_liked_any_comment ? ' ' : ''"
						:mentioned="comment.mentioned"
						:post-id="id"
					/>
					<div
						v-if="total_comments === 0 && isConsumer"
						@click="checkOpenDetailPage(id, publication_status, true)"
						class="post-page-url"
					>
						<!-- <template v-if="total_comments"> Смотреть все комментарии </template> -->
						<template>Прокомментировать</template>
					</div>
				</div>
			</slot>
		</div>
		<girl-verification-needs-dialog
			v-if="showVerificationNeedsDialog"
			@close-dialog="showVerificationNeedsDialog = false"
		/>
		<dialog-component v-if="showVerificationProcessDialog" @close="showVerificationProcessDialog = false">
			<template #header-title>Верификация. Почти готово.</template>
			<p class="status-dialog-text">
				Нужно немного подождать, пока мы проверим данные. Обычно процесс занимает не более 8 часов. После этого тебе
				станет доступен просмотр чужих постов, а твои собственные посты опубликуются автоматически.
			</p>
			<button-component :loading="isSending" @click="showVerificationProcessDialog = false"> Отлично </button-component>
		</dialog-component>
		<dialog-component v-if="showVerificationRejectedDialog" @close="showVerificationRejectedDialog = false">
			<template #header-title>Требуется верификация.</template>
			<p class="status-dialog-text">Мы не смогли убедиться, что ты — настоящая. Попробуй повторить шаги верификации.</p>
			<button-component :loading="isSending" @click="startVerification"> Повторить верификацию </button-component>
		</dialog-component>
		<dialog-component v-if="showPostRejectedDialog" @close="showPostRejectedDialog = false">
			<template #header-title>Пост отклонен</template>
			<p v-if="rejection_reason" class="status-dialog-text text-red">От модератора: {{ rejection_reason }}</p>
			<p class="status-dialog-text">Для повторной модерации необходимо отредактировать пост</p>
		</dialog-component>
		<dialog-component v-if="showFanPostInfoDialog" @close="showFanPostInfoDialog = false">
			<template #header-title>Это пост для фанатов.</template>
			<p class="status-dialog-text">Его могут увидеть только подписчики этого автора. И&nbsp;сам автор, конечно.</p>
		</dialog-component>
		<dialog-component v-if="showInfoAboutLike" @close="showInfoAboutLike = false">
			<template #header-title>Чем сильнее симпатия, тем больше лайков!</template>
			<p class="status-dialog-text">
				Каждому посту можно поставить любое количество лайков. <span class="iconInfo iconInfo--heart" /><span
					class="iconInfo iconInfo--heart"
				/><span class="iconInfo iconInfo--heart" /> <br /><br />
				Чем больше лайков, тем чаще пост появляется в рекомендациях — так автор становится популярным.
				<span class="iconInfo iconInfo--princess" /> <br /><br />
				Самые активные пользователи получают награды от благодарных авторов. 💎 <br /><br />
				Заходите каждый день, чтобы получить как можно больше лайков и собрать коллекцию эксклюзивных наград,
				посвященных именно вам! <span class="iconInfo iconInfo--cup" /><br />
			</p>
		</dialog-component>
		<dialog-component v-if="showPostOptionsDialog" @close="showPostOptionsDialog = false">
			<template #header-title />
			<ul class="post-options-list">
				<li :class="['post-options-item', { 'post-options-item--disable': progress > 0 }]">
					<button type="button" class="post-options-action" @click="onEdit">Редактировать</button>
				</li>
				<li class="post-options-item" v-if="can_be_deleted">
					<button type="button" class="post-options-action link-pink" @click="onDelete">Удалить пост</button>
				</li>
			</ul>
		</dialog-component>
	</div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useCreatePostStore } from '@/stores/createPost'
import { useProfileStore } from '@/stores/profile'
import { usePostsStore } from '@/stores/posts'
import { useModalStore } from '@/stores/modal'
import { usePostStore } from '@/stores/post'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { observer } from '@/helpers/postViewObserver'
import CommentComponent from '@/components/CommentComponent.vue'
import PostMediaVideo from '@/components/PostMediaVideo.vue'
import PostMediaImage from '@/components/PostMediaImage.vue'
import PostLike from '@/components/PostLike.vue'
import SubscribeButton from '@/components/SubscribeButton.vue'
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue'
import AvatarCircle from '@/components/AvatarCircle.vue'
import PostStatusForGirl from '@/components/PostStatusForGirl.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import GirlVerificationNeedsDialog from '@/components/dialogs/GirlVerificationNeedsDialog.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { dayMonthYearNumeric, hoursMinutes } from '@/helpers/dateFormat'
import { useSubscribeStore } from '@/stores/subscribe'
import messengerApi from '@/http/messenger'
import { useGuestMessengerStore } from '@/stores/guestMessenger'

export default {
	name: 'PostComponent',
	components: {
		GirlVerificationNeedsDialog,
		ButtonComponent,
		DialogComponent,
		PostStatusForGirl,
		TextWithHashtagsAndMentioned,
		AvatarCircle,
		SubscribeButton,
		CommentComponent,
		PostMediaVideo,
		PostMediaImage,
		PostLike,
		SvgIcon
	},
	props: {
		author: {
			type: Object,
			default: () => {}
		},
		comment: {
			type: Object,
			default: () => {}
		},
		total_likes: { type: Number, default: 0 },
		total_comments: { type: Number, default: 0 },
		total_consumer_likes: { type: Number, default: 0 },
		description: { type: String, default: '' },
		hashtags: { type: Array, default: () => [] },
		mentioned: {
			type: Object,
			default: () => {}
		},
		published_text: { type: String, default: '' },
		id: { type: Number, default: 0 },
		attachments: {
			type: Object,
			default: () => {}
		},
		audience: { type: String, default: '' },
		visibility: { type: String, default: '' },
		girl_liked_any_comment: Boolean,
		rejection_reason: { type: String, default: '' },
		publication_status: { type: String, default: '' },
		onClickNicknameFunction: { type: Function, default: undefined },
		should_be_published_at: { type: String, default: '' },
		can_be_scheduled: { type: Boolean, default: false },
		can_be_deleted: { type: Boolean, default: false },
		background: { type: Boolean, default: true },
		detailPage: { type: Boolean, default: false },
		detailPopUp: { type: Boolean, default: false }
	},
	data() {
		return {
			showVerificationNeedsDialog: false,
			showVerificationProcessDialog: false,
			showVerificationRejectedDialog: false,
			showPostRejectedDialog: false,
			showFanPostInfoDialog: false,
			showPostOptionsDialog: false,
			isSending: false,
			showInfoAboutLike: false
		}
	},
	computed: {
		...mapState(useProfileStore, {
			consumerId: 'id',
			hasCardPayment: 'hasCardPayment',
			totalLikes: 'total_likes',
			subscriptionsData: 'subscriptionsData',
			girl_subscription_restricted: 'girl_subscription_restricted'
		}),
		...mapWritableState(useCreatePostStore, ['post']),
		...mapState(useVideoUploadStore, ['progress']),
		...mapState(useModalStore, {
			showDetailPage: 'showDetailPage',
			openSubLimitErrorModal: 'openSubLimitErrorModal'
		}),
		...mapState(usePostsStore, {
			viewedPosts: 'viewedPosts'
		}),
		postLabelIconSize() {
			if (window.screen.width > 980) {
				return '13px'
			}
			return '10px'
		},
		shouldBePublishedAt() {
			const date = dayMonthYearNumeric(this.should_be_published_at.replace(/-/g, '/'))
			const time = hoursMinutes(this.should_be_published_at.replace(/-/g, '/'))
			return `${date} в ${time}`
		},
		previewLettersCount() {
			if (window.screen.width > 980) {
				return 114
			}
			return 80
		},
		verificationStatus() {
			const girlProfileStore = useGirlProfileStore()
			return girlProfileStore.verification_status
		},
		isOwnPost() {
			const girlProfileStore = useGirlProfileStore()
			const currentGirlId = girlProfileStore.id
			return this.postAuthorId === currentGirlId
		},
		isLocked() {
			return this.visibility === 'lock' && !this.isWaitingSubscriptionResult
		},
		isBluredLoader() {
			return this.visibility === 'lock' && this.isWaitingSubscriptionResult
		},
		type() {
			if (this.attachments && this.attachments.photos && this.attachments.type === 'photo') {
				return this.attachments.photos.length > 1 ? 'carousel' : 'image'
			}
			return 'video'
		},
		postAuthorId() {
			return this.author.id
		},
		girlProfileLink() {
			return {
				name: 'author-profile',
				params: { nickname: this.author.nickname, id: String(this.postAuthorId) }
			}
		},
		isPublishedPost() {
			return this.publication_status === 'published'
		},
		isScheduledPost() {
			return this.should_be_published_at
		},
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		isGuest() {
			return JSON.parse(this.$auth.remember())?.role === 'guest'
		},
		anyCommentNotLiked() {
			return this.total_comments > 0 && !this.girl_liked_any_comment
		},
		hasAttachedFile() {
			if (this.attachments.video && this.type === 'video') {
				return this.attachments.video.file !== null
			}
			if (this.attachments.photos) return this.attachments.photos.length > 0
			return false
		},
		isWaitingSubscriptionResult() {
			return this.author.waiting_subscription_result
		},
		isSubscribeRenewable() {
			return !this.author.stopped_subscription_renewal
		},
		subLink() {
			const host = this.$config.apiUrl
			if (this.audience === 'all') {
				return `${host}consumer/service/subscribe-from-post?token=${this.$auth.token()}`
			}
			if (this.postForFans) {
				return `${host}consumers/${this.consumerId}/girls/${this.postAuthorId}/subscribe`
			}
			return ''
		},
		postForFans() {
			return this.audience === 'fans'
		},
		hasBlurredImage() {
			if (this.attachments?.type === 'video') {
				const { blurred_thumbnail, blurred_image } = this.attachments.video.preview
				return blurred_thumbnail || blurred_image?.url
			}
			const { blurred_thumbnail, blurred_image } = this.attachments.photos[0]
			return blurred_thumbnail || blurred_image?.url
		},
		showPrivateBtn() {
			return this.$config.feedShowPrivate && !this.showDetailPage && (this.isConsumer || this.isGuest)
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['setPost', 'setMode', 'openCreatePostDialog', 'setScrollY', 'setPreview']),
		...mapActions(useModalStore, {
			openVerificationDialog: 'openVerificationDialog',
			openDetailPage: 'openDetailPage',
			closeDetailPage: 'closeDetailPage'
		}),
		...mapActions(useSubscribeStore, ['checkSubscribeLimitState']),
		...mapActions(usePostStore, { deletePost: 'deletePost' }),
		...mapActions(useGuestMessengerStore, {
			setCurrentGirl: 'setCurrentGirl'
		}),
		checkOpenDetailPage(id, publication_status, scrollToComment = false) {
			if (this.detailPage) {
				return
			}
			this.openDetailPage(id, publication_status, scrollToComment, this.$props)
		},
		getNaturalSize() {
			const naturalSize = {}
			if (this.type === 'video') {
				const currentContent = this.$refs.mediaContent.querySelector('video')
				naturalSize.naturalWidth = currentContent.width
				naturalSize.naturalHeight = currentContent.height
			} else {
				const currentContent = this.$refs.mediaContent.querySelector('img')
				naturalSize.naturalWidth = currentContent.naturalWidth
				naturalSize.naturalHeight = currentContent.naturalHeight
			}
			return naturalSize
		},
		showPopUpAboutLike() {
			if (this.totalLikes === 0) {
				this.$metrika.reachGoal('onbording-like')
				this.showInfoAboutLike = true
			}
		},
		onVideoLoaded() {
			if (this.detailPage) {
				this.$emit('videoLoaded')
			}
		},
		openSubscriptionPage(e) {
			e.stopPropagation()
			if (this.isGuest) {
				window.location.href = `${this.$config.apiUrl}guest/girls/${this.author.id}/subscribe`
				return
			}
			if (this.girl_subscription_restricted) {
				this.openSubLimitErrorModal()
				return
			}
			if (this.checkSubscribeLimitState(this.avatar)) {
				return
			}
			if (this.isConsumer) {
				window.location.href = this.subLink
			}
			if (this.isGirl) {
				switch (this.verificationStatus) {
					case 'needs': {
						this.showVerificationNeedsDialog = true
						break
					}
					case 'in_process': {
						this.showVerificationProcessDialog = true
						break
					}
					case 'rejected': {
						this.showVerificationRejectedDialog = true
						break
					}
					case 'approved': {
						this.showFanPostInfoDialog = true
						break
					}
					default: {
						break
					}
				}
			}
		},
		onClickNickname(e) {
			e.preventDefault()
			if (this.onClickNicknameFunction) {
				this.onClickNicknameFunction(this.author.nickname)
				return
			}
			this.$router.push(this.girlProfileLink)
		},
		onEdit() {
			this.showPostOptionsDialog = false
			if (this.progress > 0) {
				return
			}
			let post = {}
			if (this.type === 'image') {
				post = {
					photo_id: this.attachments.photos[0].id,
					photo: this.attachments.photos[0].url,
					description: this.description,
					audience: this.audience,
					should_be_published_at: this.should_be_published_at,
					can_be_scheduled: this.can_be_scheduled
				}
			}
			if (this.type === 'video') {
				post = {
					video_id: this.attachments.video.id,
					video: this.attachments.video.file,
					video_preview_id: this.attachments.video.preview.id,
					video_preview_url: this.attachments.video.preview.url,
					description: this.description,
					audience: this.audience,
					should_be_published_at: this.should_be_published_at,
					can_be_scheduled: this.can_be_scheduled
				}
			}
			this.setPost(post, this.id, 'edit')
			if (this.attachments.video?.file === null && this.isOwnPost) {
				this.setPreview(this.attachments.video?.preview?.url, true)
			}
		},
		async onDelete() {
			this.showPostOptionsDialog = false
			this.isSending = true
			await this.deletePost(this.id)
			this.showPostRejectedDialog = false
			this.isSending = false
		},
		startVerification() {
			this.openVerificationDialog()
			this.showVerificationNeedsDialog = false
			this.showVerificationRejectedDialog = false
		},
		observeViewEvent() {
			const element = document.getElementById(String(this.id))
			if (!this.isLocked) {
				observer.observe(element)
			}
		},
		showPostOptionsModal() {
			this.setScrollY(window.scrollY)
			this.showPostOptionsDialog = true
		},
		async openChat() {
			if (this.isGuest) {
				this.setCurrentGirl(this.author.id, this.author.avatar)
				await this.$router.push({
					name: 'private',
					params: { id: this.author.nickname }
				})
			} else {
				const { recipient, thread_id } = await messengerApi.locateThread('girl', this.author.id)
				let roomName = recipient.name
				if (!thread_id) {
					const newThread = await messengerApi.initializeThread({
						recipient_alias: recipient.provider_alias,
						recipient_id: recipient.provider_id
					})
					roomName = newThread.name
				}
				await this.$router.push({ name: 'private', params: { id: roomName } })
			}
		}
	},
	mounted() {
		if (!this.isGirl) {
			this.observeViewEvent()
		}
	}
}
</script>

<style lang="scss">
$padding-x: 15px;
.post {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	padding: 10px 0;
	scroll-margin-top: 50px;
	margin-bottom: 8px;
	border-radius: 12px;
	@media (min-width: $screen-desktop) {
		padding: 15px 0;
	}
	&-background {
		background: rgba($color-black-secondary, 0.5);
	}

	&-header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: flex-start;
		padding: 0 $padding-x;
		position: relative;
	}

	&-author-info {
		display: flex;
		gap: 10px;
	}

	&-options {
		&-list {
			list-style-type: none;
		}

		&-item {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		&-action {
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			color: $color-white;
			background: transparent;
			border: none;
			cursor: pointer;
			padding: 5px 0;
		}
	}

	&-description {
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		color: $color-white;
		padding: 0 $padding-x;
	}

	&-options-button {
		color: $color-gray;
		background: transparent;
		border: none;
		font-size: 20px;
		cursor: pointer;
		padding: 5px;
		transform: translateX(15px);
		transition: $transition-color;
		&:hover {
			color: $color-gray-hover;
		}
		&:hover {
			color: $color-gray-active;
		}
	}

	&-labels {
		display: flex;
		gap: 6px;
		padding: 2px 0;
		margin-top: 2px;
	}

	&-label {
		border-radius: 50px;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		color: $color-white;
		padding: 0 4px;
		display: flex;
		align-items: center;
		gap: 3px;
		@media (min-width: $screen-desktop) {
			font-size: 14px;
			padding: 1px 4px;
		}
	}

	&-audience {
		background: $color-blue-dark;
	}

	&-planned-date {
		background: $color-magenta;
	}

	&-release-date {
		padding: 0;
		color: $color-gray;
	}

	&-status {
		padding: 0 $padding-x;
	}

	&-like-panel {
		padding: 0 $padding-x;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-actions {
		display: flex;
		color: $color-gray-additional-seventh;
		gap: 10px;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;

		& > * {
			background: $color-black-secondary;
			height: 32px;
			display: flex;
			align-items: center;
			gap: 5px;
			padding: 0 11px;
			border-radius: 50px;
		}
	}

	&-total-likes {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: rgba($color-white, 50%);
	}

	&-comments {
		padding: 0 $padding-x;

		.comment-like {
			margin-top: 5px;
		}
	}

	&-page-url {
		margin-top: 5px;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: $color-gray;
		display: block;
	}
}

.status-dialog-text {
	margin-bottom: 26px;
	color: $color-gray;
	font-size: 16px;
	line-height: 20px;
}

.post-media {
	user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
}
.post-media-published {
	cursor: pointer;
	& video,
	img {
		transition: $transition-opacity;
	}
	&:hover {
		& video,
		img {
			opacity: 0.7;
		}
	}
}

.iconInfo {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url('@/assets/images/icons/sportsmedal.png');
}

.iconInfo--princess {
	background-image: url('@/assets/images/icons/princess.png');
}

.iconInfo--heart {
	background-image: url('@/assets/images/icons/heart.png');
}

.post-comment-link {
	color: $color-gray-additional-sixth;

	&-pink {
		color: $color-magenta;
	}
}

.post-options-item--disable {
	opacity: 30%;
}
</style>

<style lang="scss" scoped>
.post-about {
	display: flex;
	flex-direction: column;
	gap: 9px;
}

.post-total-likes {
	white-space: nowrap;
}
.liked-comments-label {
	background: none;
	color: $color-magenta;
	font-size: 12px;
	line-height: 1;
	transition: $transition-color;
	cursor: pointer;
	padding: 0;
	&:hover {
		color: $color-magenta-hover;
	}
	&:active {
		color: $color-magenta-active;
	}
}
.dialog-post {
	.liked-comments-label {
		display: none;
	}
}
.private-link {
	background: linear-gradient(90deg, #34335f -100%, #72406d 120%);
}
@media (min-width: $screen-desktop) {
	.liked-comments-label {
		font-size: 16px;
	}
	.post-name,
	.post-page-url,
	.post-total-likes,
	.post-comment-link,
	.post-like,
	.post-options-action,
	.private-link {
		transition: $transition-color;
		cursor: pointer;
	}
	.post-header::v-deep .post-subscribe,
	.post-name,
	.post-total-likes,
	.post-page-url,
	.post-description,
	.post-description::v-deep a {
		font-size: 16px;
	}
	.post-audience {
		font-size: 14px;
		padding: 2px 6px;
	}
	.post-author-info {
		img {
			transition: $transition-opacity;
		}
		&:hover {
			.post-name {
				color: $color-white-hover;
			}
			img {
				opacity: 0.7;
			}
		}
	}
	.post-total-likes,
	.post-comment-link,
	.post-like,
	.post-options-action,
	.private-link {
		&:hover {
			color: $color-white-hover;
		}
		&:active {
			color: $color-white-active;
		}
	}
	.post-comment-link-pink,
	.link-pink {
		&:hover {
			color: $color-magenta-hover;
		}
		&:active {
			color: $color-magenta-active;
		}
	}

	.post-page-url {
		&:hover {
			color: $color-gray-hover;
		}
		&:active {
			color: $color-gray-active;
		}
	}

	.post-header::v-deep {
		& .avatar-circle {
			width: 50px;
			height: 50px;
			min-width: 50px;
			min-height: 50px;
		}
	}

	::v-deep {
		.comment-nickname {
			font-size: 16px;
		}
		.comment-text {
			font-size: 16px;
		}
	}
	.post-detail-popup {
		::v-deep {
			.post-subscribe-dialog {
				margin-right: 0;
			}
			.placeholder-bg {
				animation: none;
			}
			.post-subscribe {
				font-size: 12px;
				right: 0;
			}
			.release {
				font-size: 14px;
				margin-top: 5px;
			}
		}
		.post-media {
			height: 100%;
			background: rgba($color-black-secondary, 0.5);
			border-radius: 16px 0 0 16px;
			overflow: hidden;
			width: 100%;
			::v-deep {
				.post-photo {
					height: 100%;
				}
				img,
				video {
					cursor: default;
					object-fit: contain;
					height: 100%;
				}
			}
		}

		.post-about {
			height: 100%;
			overflow: scroll;
			max-width: 382px;
			min-width: 382px;
			padding: 16px 26px;
			background: $color-black;
			border-radius: 0 16px 16px 0;
			&-with-comment {
				height: calc(100% - 90px);
			}
		}

		.post-header {
			padding: 0;
			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid rgba($color-black-secondary, 1);
		}

		.post-author-info {
			display: flex;
			gap: 10px;
			padding: 0;
		}

		.post-like-panel,
		.post-description,
		.post-comments {
			padding: 0;
		}
	}
}

.link-pink {
	color: $color-magenta;
}
</style>
